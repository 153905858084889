import React from "react"
import FLayout from "../../components/flayout";
import InquiryForm from "../../components/inquiryForm";
import SignUpInvestorsGraphic from "../../gatsby-images/signup-investors-graphic";
import { Customer_Inquiry_Type } from "../../common/constants";

class Page extends React.Component {
    render() {
        return (
          <div className="v-100 d-flex flex-column">
          <FLayout showHeaderFooter={false} darkHeader={true} isFullPage={true} headerFixed={false} showSingleFooter={true} noSelBackground={true} seoFields={seoFields} showAnnouncement={false} loadTimesLandingPixel={true} showB2XTypeSelection={false} onlyLogo={true}>
            <InquiryForm formOnRight={true} Graphic={SignUpInvestorsGraphic} location={this.props.location}
              headingText={'Manage multi-asset investments with MProfit'}
              bodyText={`Auto-import your trade data for Stocks, Mutual Funds & other assets from 700+ brokers. Track your consolidated XIRR, get Capital Gain reports and gain advanced portfolio insights!`}
              inquiryRequestTitle={'Request pricing'}
              inquiryRequestCTA={'Request pricing'}
              inquiryType={Customer_Inquiry_Type.PRICING}
            />
          </FLayout>
          </div>
        )
    }
}

const seoFields = {
  description: "Request pricing for MProfit plans and get in touch with an MProfit representative",
  // lang,
  // meta,
  // keywords,
  title: "Request Pricing" 
}

export default Page